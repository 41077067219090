import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import {Link} from 'react-router-dom';


//import "./Contact.css";
import Meta from './subcomponents/Meta';
import Oops from './subcomponents/Oops';
import LinkButton from './subcomponents/LinkButton';

export default function Contact () {

  const [email, setEmail] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSubmit, setIsSubmit] = useState(0);

  function handleChange(e) {
    const {name, value} = e.target;
    setEmail(prevValue => {
      return{
      ...prevValue,
      [name]: value
    }});
  }

  function handleSubmit (e) {
    e.preventDefault();
    setIsSubmit(1);
    // console.log("Submit: ", email)
    axios.post('api/contact', email)
      .then(res => res.data ? setIsSubmit(2): <Oops text = "Hmm...  something didn't go right...  Let me take you to the home page and send a note to the webmaster." link="/"/>)
      // .then(res => console.log(res.data))
      .catch(err => <Oops text = "Hmm...  something didn't go right...  Let me take you to the home page and send a note to the webmaster." link="/"/>);
    return <p>Sending...</p>;
  }
  
  useEffect (()=>{
    Meta("Contact - bfmhfansite.com", "Contact - Big Farm Mobile Harvest Fansite.  Send me a message or join the discussion of Facebook.")
	},[]);
  
  if (isSubmit === 2) {
    return (<section className = "message-submit">
      <h3>Contact Page</h3>
      <h5>Thank you.  Your message was successfully submitted</h5>
      <br />
      <LinkButton link = "/" name = "home" />
    </section>)
  } 

  return(<section>
    <h3>Contact Page</h3>
    <form className = "contact-form" onSubmit = {handleSubmit} autoComplete = "off">
      <p>Hey.  I will be happy to hear from you, but...  I am not Goodgame tech support.  For game related problems, you will need to fill out a ticket (bottom of the page) over at <a href = "https://support.goodgamestudios.com/?g=38">Goodgame Tech Suppport.</a> Yes, it can take 5-21 days to hear back from them, so just make sure you get a confirmation email when you submit your ticket.  On <a href = "https://www.facebook.com/groups/BFMH.FarmersFanPage/">Facebook</a>, I have 2 posts that cover most of the problems you will encounter with details on if or how you can solve them yourself and when you need to contact support.  I hope to get them moved over here soon.  You can also join us on <a href = "https://www.facebook.com/groups/BFMH.FarmersFanPage/">Facebook.</a></p>
      <input  placeholder = "Name" name = "name" value = {email.name} onChange ={handleChange} required = {true}></input>
      <input  placeholder = "Email" name = "email" value = {email.email} onChange ={handleChange} required = {true}></input>
      <textarea placeholder = "Type your message here" name = "message" value = {email.message} onChange ={handleChange} required = {true} cols = "10" rows = "6"></textarea>
      <button type = "submit">Submit</button>
      {isSubmit === 1 && <p>Sending message...</p>}
      

    </form>
  </section>);
    
  
}