const themeLinks = [
  
  {
    key: 1,
    name: "asia",
    link: "/themes/asia"
  },
  {
    key: 2,
    name: "camping",
    link: "/themes/camping"
  },
  {
    key: 3,
    name: "wedding",
    link: "/themes/wedding"
  }
  ,{
    key: 4,
    name: "dino",
    link: "/themes/dino"
  },
  {
    key: 5,
    name: "ufo",
    link: "/themes/ufo"  
  },
  {
    key: 6,
    name: "foodtruck",
    link: "/themes/foodtruck"
  },
  {
    key: 7,
    name: "farmcup",
    link: "/themes/farmcup"
  },
  {
    key: 8,
    name: "fairytale",
    link: "/themes/fairytale"  
  },
  {
    key: 9,
    name: "museum",
    link: "/themes/museum"
  },
  {
    key: 10,
    name: "hawaii",
    link: "/themes/hawaii"
  },
  {
    key: 20,
    name: "easter-theme",
    link: "/themes/easter"
  },
  {
    key: 21,
    name: "halloween",
    link: "/themes/halloween"
  },
  {
    key: 22,
    name: "winter-wonderland",
    link: "/themes/winter-wonderland"
  }
];

export default themeLinks;