import React, { useState, useEffect} from "react";
import axios from 'axios';
// import "./Calendar.css";
import InfoButton from "./subcomponents/InfoButton";


// Takes the time remaining and returns a string of days/hours or hours/mins with pural correction
export function remainingString(targetDate, currentDate) {

	function plural (item, text) { 
		return item + " " + text + (item !== 1 ? "s" : "")
	}

	let timeRemaining = targetDate - currentDate;
	let days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
	let hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	let minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));

	// console.log("days: " + days + " hours: " + hours + " minutes: " + minutes);
	
	return (days === 0 ? plural(hours, "hour") + ", " + plural(minutes, "min"):plural(days, "day") + ", " + plural(hours, "hour"));
}

// Main calendar box function
export default function Calendar (props) {
	
	const [calendarEvents, setCalendarEvents] = useState([]);
	const [dateToggle, setDateToggle] = useState(true);

	let todayDate = new Date();
	
	function parseISOString(s) {
		let b = s.split(/\D+/);
		return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
	}

	useEffect(()=>{
		//axios.get('http://localhost:5000/calendar/')
    axios.get('/api/calendar/')
      .then(res => {
				res.data.forEach(item => {
					item.startDate = parseISOString(item.startDate);
					item.endDate = parseISOString(item.endDate);
				});
				setCalendarEvents(res.data);
      });
  },[]);

	// Changes for date to countdown 	
	
	useEffect(() => {
		const timer = setTimeout(()=> {
			setDateToggle(dateToggle => !dateToggle);
		}, 5000);
		return () => {
			clearTimeout(timer);
		};
	});

	//console.log(test);
	// takes the events and breaks them into sorted current and future events
	const currentEvents = calendarEvents
		.filter((event)=> event.startDate < todayDate) // grabs events that have already started
		.filter((event)=> event.endDate > todayDate) // and then removes dates that have finished
		.filter((event)=> event.suspend !== true)    // remove suspended events
		.sort((a, b)=>new Date(a.endDate) - new Date(b.endDate)); // and then sorts by ending soonest ending first
	// console.log ("current events", currentEvents);
	

	const futureEvents = calendarEvents
		.filter((event)=> event.startDate > todayDate) // grabs events that are still to run 
		.filter((event)=> event.suspend !== true)
		.sort((a, b)=>new Date(a.startDate) - new Date(b.startDate)) // and sorts by starting the soonest first
		.slice(0, 10); // limit the number of items in the calendar.
	// console.log ("future events", futureEvents);

  
  return(
    <div className="calender-widget">
      <h2 className="ce-main-title">Current Events<span className = "watermark">bfmhfansite.com</span></h2>
			{currentEvents.map((event, i) =>{
				return (
					<div key = {i} className="calendar-event-box" style={{"borderColor": event.color}}>
						{event.link && <InfoButton link = {event.link} />}
						<img className="calendar-event-thumb" src = {"/images/calendar/" + event.pic + "-thumb.png"} alt={event.name}/>
						<p className = "calendar-event-title">{event.name}</p>
						<p className = "calendar-event-date">{event.end ? event.end : dateToggle ? "Ends " + event.endDate.toDateString() : "Ends in " + remainingString(event.endDate, todayDate)}</p>
					</div>)
				})} 
				{/* mapCurrentElement(event, todayDate))} */}
			<h2 className="ce-main-title">Upcoming Events<span className = "watermark">bfmhfansite.com</span></h2>
			{/* {futureEvents.map(event=>mapFutureElement(event, todayDate))} */}
			{futureEvents.map((event, i)=>{
				return (
					<div key = {i} className="calendar-event-box" style={{"borderColor": event.color}}>
						{event.link && <InfoButton link = {event.link} />}
						<img className="calendar-event-thumb" src = {"/images/calendar/" + event.pic + "-thumb.png"} alt={event.name}/>
						<p className = "calendar-event-title">{event.name}</p>
						<p className = "calendar-event-date">{event.start ? event.start : dateToggle ? "Starts " + event.startDate.toDateString() : "Starts in " + remainingString(event.startDate, todayDate)}</p>
					</div>)
				})} 
		</div>
  )
}

