import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

// import "./Blog.css";
import Meta from './subcomponents/Meta';

export default function Blog () {
  
  const [blogs, setBlogs] = useState([]);
  
  useEffect(()=>{
    Meta("Blogs - bfmhfansite.com", "Blogs - Big Farm Mobile Harvest Fansite.  Collection of blogs on game dynamics, better farming, building a better co-op, or life improvement in general.")
		axios.get('api/blog/')
      .then(res => {
        // console.log("resulting blog search: ", res.data);
        setBlogs(res.data);
   
      });
  },[]);

  function BlogCard (props) {
    return(<div className = "card-preview">
        <h2 className = "title">{props.title}</h2>  
        <img className = "cover-img" src = {props.cover} alt = "Blog Preview" />
        <p className = "excerpt">{props.excerpt}</p>
        <Link  className = "excerpt" to={"/blog/" + props.link}>Read More...</Link>       
    </div>)
  }
  
  return(
    <main id = "blog-main">
      <h3>Big Farm Mobile Harvest Fansite Blogs</h3>
      <p className = "blurb">This is where you will find more reflective style posts on game dynamics, better farming, co-op building and life improvement.  More games mechanics specific items can be found in the <Link to = "/guides">guides</Link> section.</p>
      <br/>
      <section className = "preview">
        {blogs.map((blog, i) => {
          return(<BlogCard
            key = {i}
            title = {blog.title}
            excerpt = {blog.excerpt}
            link = {blog.link}
            cover = {blog.cover}
          />)
        })}
      </section>
        
      <div className = "menu">
      </div>
    </main>    
  );
}