import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
// import { Redirect } from "react-router-dom";

import LinkButton from "./subcomponents/LinkButton";
// import seasonalLinks from "../data/seasonal-menu";



import Loading from "./subcomponents/Loading";
import Meta from "./subcomponents/Meta";
import Oops from "./subcomponents/Oops";
import parseBlog from "./subcomponents/ParseBlog";

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

function TableRow (props) {
  const {data, header, stage} = props;
 
  return (<tr style = {stage ? {border: "5px solid #CB291D"} : null}>
    {header.map((col, i) => {         // map each item in the header
      
      if (data[col] === 999) {
        data[col] = "?"
      }
      
      if (data[col] === undefined) {    // this data type does not exist, return an empty cell
        return <td key = {i}> </td>
      } else if (typeof data[col] === "object") {
          const items = data[col];
          //console.log(items);
          return <td key = {i}> {
            Object.keys(items).map((key, i) => {
              items[key] === 999 && (items[key] = "?");
              return <div style = {{"position": "relative"}} key = {i}>
                {numberWithCommas(items[key])}<img className = {key === "unlock" ? "seasonal-unlock": (col === "task" ? "seasonal-task": "building-level")} src = {"/images/seasonal/"+ key +".png"} alt={key}></img><br />
              </div>
            })
          }</td>
      } else {
        return <td key={i}> {data[col]}<img className = "building-level" src = {"/images/seasonal/"+ col +".png"} alt={col}></img> </td>
      }
      

    })}
  </tr>)
}

// ************** Main Function *****************

export default function Buildings () {
  
  useEffect(()=>{
    const viewportmeta = document.querySelector('meta[name=viewport]');
    viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.0");
  },[])


// **************************************************************************************************************************************  
  const [events, setEvents] = useState();
  const [fullMenu, setFullMenu] = useState(false);
  
  const {eventUrl} = useParams();
 

  let event;
  // let defaultEvent = 8;

  useEffect(()=>{
    axios.get('/api/seasonal-event')
      .then(res => {
        setEvents(res.data);
        // {console.log(res.data)}
      });
  },[]);  

  let visibleEvents = [];
  if (events === undefined) {
    return<Loading text = "Loading events..." />
  } else {
    visibleEvents = events.filter((event)=>event.visible === true);
            
    if (eventUrl === undefined) {

      event = visibleEvents[visibleEvents.length - 1];  //grabs the last visible link
      
    } else {
      event = events.filter((event)=> event.link === eventUrl)[0];   // grab the event from the parameter pass
      if (event === undefined) {
        return <Oops text = "That event doesn't seem to exist...  I'll take you to the seasonal events" link="/seasonal"/>
      }
    }
  }

  let headerList = event.taskHeader;
  
  let metaDescription = `All of the information you need for Big Farm Mobile Harvest's Seasonal event ${event.name}.`;
  if (event.pdescription !== undefined) {
    metaDescription = event.pdescription;
  }
  
  Meta(`${event.name} - bfmhfansite.com`, metaDescription);

  function createMarkup(innerHtml) { return {__html: parseBlog(innerHtml)}; }
 
  // let cookies = new Date().getTime() - new Date ("03-24-2022 08:00:00").getTime();
  let collectables = new Date().getTime() - new Date ("2022-12-07T08:00:00.000Z").getTime();
  
  
  // console.log("date", new Date().getTime());
  // console.log("Start ", new Date ("2022-03-24T08:30:00.000Z").getTime());
  // console.log("difference", cookies)
  collectables = Math.floor(collectables/1000/60/60*20);   //add the rate per hour.  
  let stage = 0;
    // console.log("Items needed: ", collectables)

  event.tasks.map((event, i) => {
    if (typeof(event.task["cookie"]) === "number") {
      if (event.task["cookie"] <= collectables) {
        collectables -= event.task["cookie"]
      } else {
        if (stage === 0) stage = i;
      }
      // console.log("Stage: " + i, stage + ",  Cookies: " +  collectables, event.task["cookie"])
    }
    return null
  });

  function MenuOnclick () {
    setFullMenu (!fullMenu);
  }
 
    
  return (
    
    <div className = "building-tables" >

      <nav className="menu-content building-menu">

        <button className="button">
          <img className = "button-icon" onClick={MenuOnclick} src={"/images/menu/"+ (fullMenu ? "less": "more") + ".png"} alt={"more button"} />
        </button>
        {visibleEvents.slice(visibleEvents.length-(fullMenu ? visibleEvents.length : 5)).map((event, i)=>{return <LinkButton key = {i} name = {event.thumb} link = {"/seasonal/"+ event.link} />})}
        
        {/* {seasonalLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })} */}
        
      </nav>

      <article className = "blog-post">
        <h2 className = "blog-title">{event.name}</h2>
        <div className = "blog-content" dangerouslySetInnerHTML={createMarkup(event.html)}/>
        {event.link === "winter-2022" && 
          <div className = "blog-post blog-content">
            <h4>How many Christmas Cookies should I have?</h4>
            <p>This year's event is 1 week shorter.  16750 cookies in 35 days = 480 cookies per day.  This tracker is approximately where you should be to finish the Christmas Tree on time.  It is evenly split to the minute, so it keeps going up while you sleep.  There is one extra day to spare at the end, but the event will end in the early hours of the day.  Refresh the page for the most accurate numbers.  The active task is highlighted in red.</p>
            {collectables < 0 ? <p>Event has not started yet.</p>:(
            
            stage < 40 ? 
            <p className = "notranslate">{collectables} of {event.tasks[stage].task["cookie"]}
              <img className = "building-level" src = {"/images/seasonal/cookie.png"} alt={"Christmas Cookie"}/> - task {stage+1} ({event.tasks[stage].reward.xp}
              <img className = "building-level" src = {"/images/seasonal/xp.png"} alt={"xp"}/>)
            </p>:<p>You should be done.</p>  ) }
          </div>}
      </article>

  
      {/* ********* Table Name ********** */}

      <div style = {{"display": "block", "Postition": "relative", "margins": "auto"}} >
        <div className = "seasonal-header" >
          <h2>{event.name}</h2>
          <p className = "watermark">bfmhfansite.com</p> 
                    
        </div>
      
        
        {/* ********** Table ************** */}
        <div className = "building-table-overflow">
          <table id= "seasonal-table" className = "building-table">

            <thead>
              <tr>
                {headerList.map((title, i) => {return <th key = {i}>{(title.charAt(0).toUpperCase() + title.slice(1)).replace("-", " ")}</th>})}
              </tr>
            </thead>  
            <tbody className = "notranslate">
              {event.tasks.map((task, i) => <TableRow key = {i} data = {task} header = {headerList} stage = {(stage=== i && event.link === "winter-2022" )? true: false} />)}
            </tbody>
          </table>
        </div>
              
      </div>  
    </div>
  )

}
